$primary-color: #7b0051;
$secondary-color: #007a87;

$item-name-font-size: 1.5em;
$item-inner-spacing: 0.3em;
$item-horizontal-padding: 0.3em;

$primary-font-size: 1em;
$secondary-font-size: 0.8em;
$notes-font-size: 0.7em;

$paper-width: 8.5in;
$paper-height: 11in;

body {
  background: #eee;
}

.cv-page {
  background: white;
  display: block;
  margin: 0.4cm auto 0.4cm;
  box-shadow: 0 1px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
  max-width: $paper-width;
  box-sizing: border-box;
  padding: 0.4cm;
}

.cv-page.resume {
  height: $paper-height;
  padding-bottom: 0;
}

.cv-page > header > h1 {
  margin-top: 0;
}

.section-header {
  //color: $primary-color;
  font-size: 1.3em;
  line-height: 1em;
  color: $secondary-color;
  border-bottom: solid $secondary-color 2px;
  padding-left: $item-horizontal-padding;
  padding-right: $item-horizontal-padding;
}

.cv-page header div {
  vertical-align: bottom;
}

.cv-page header div .info {
  display: inline-block;
  color: $secondary-color;
  vertical-align: bottom;
  margin-bottom: 0;
  margin-left: 1em;
}

.cv-page header div .info a {
  color: $secondary-color !important;
}

.cv-page {
  position: relative;
  z-index: 0;
}

.cv-page > section {
  margin-top: $item-inner-spacing;
}

.cv-page header h1 {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.item {
  position: relative;
  margin-top: 0.2em;
  padding-left: $item-horizontal-padding;
  padding-right: $item-horizontal-padding;
}

.item > .name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: $primary-font-size;
  max-width: calc(100% - 5em);
}

.item > .date {
  position: absolute;
  right: $item-horizontal-padding;
  top: 0;
  line-height: 1.5em;
  font-size: $secondary-font-size;
}

.item > .notes {
  margin-top: 0;
  margin-bottom: 0;
  font-size: $notes-font-size;
}

.item > .notes * {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2em;
}

.item > .notes > ul > li {
  margin-top: 0;
}

.cv-page > section a {
  color: $primary-color;
}

/* Experiences */
.experience-item .experience-company.company,
.experience-location.location {
  margin-top: 0;
  display: inline-block;
  font-size: $secondary-font-size;
  line-height: $secondary-font-size;
  font-weight: normal;
  margin-bottom: 0;
}

.experience-item .notes a {
  text-decoration: underline;
}

.meta-row {
  line-height: 0.7em;
}

.experience-item .experience-company {
  margin-right: 0.5em;
}

.publication-item .name {
  font-weight: normal;
  font-size: 0.9em;
}

.publication-item .publication-authors {
  font-size: 0.8em;
  margin-bottom: 0;
}

.publication-item .publication-authors a {
  color: black;
}

.publication-item .publication-authors a.publication-me {
  color: $primary-color;
  text-decoration: underline;
}

.publication-item .publication-venue {
  font-size: 0.7em;
  margin-bottom: 0;
  font-style: italic;
}

.press-item .press-name {
  font-weight: normal;
  font-size: 0.9em;
}

.press .press-publisher {
  font-size: 0.7em;
  margin-bottom: 0;
  font-style: italic;
}

section p.award-awarded-by {
  font-size: 0.8em;
  margin-bottom: 0;
}

.skill .section-header {
  margin-bottom: 0.5em;
}

.skill-item {
  margin-top: -0.2em;
}

section .skill-item > p {
  font-size: 0.8em;
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.toolbar {
  position: absolute;
  right: calc((100vw - #{$paper-width}) / 2 - 11em);
  width: 10em;
  max-width: calc((100vw - #{$paper-width}) / 2 - 2em);
  box-sizing: border-box;
  min-width: 150px;
}

.toolbar .toolbar-paper {
  padding: 0.5em;
  width: 100%;
}

.toolbar .toolbar-paper > button {
  width: 100%;
}

.toolbar .toolbar-paper > div.should-feature {
  margin-bottom: -15px;
}

.toolbar .toolbar-paper > div.should-feature > span {
  position: relative;
  top: -5px;
}
.show-mobile {
  display: none;
}

.show-desktop {
  display: unset;
}

/* Print Styling */
@page {
  size: letter;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .toolbar {
    display: none;
  }

  .item > .date {
    position: relative;
    right: 0;
  }

  .item > .name {
    max-width: 100%;
  }

  .show-mobile {
    display: unset;
  }

  .show-desktop {
    display: none;
  }
}

@media print {
  html {
    margin: 0;
    padding: 0;
  }

  html.resume {
    height: $paper-height;
    overflow: hidden;
  }

  body {
    margin: 0;
    padding: 0;
  }

  body.resume {
    height: $paper-height;
    overflow: hidden;
  }

  .cv-page {
    border: none;
    box-shadow: none;
    margin: 0;

    width: $paper-width;
    overflow: hidden;
  }

  .hide-print {
    display: none;
  }

  .toolbar {
    display: none;
  }

  .cv-page.resume {
    height: $paper-height;
  }

  .toolbar {
    display: none;
  }
}
